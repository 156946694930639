import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { withOneTrust } from '@segment/analytics-consent-wrapper-onetrust';
import { AnalyticsBrowser, ID, UserTraits } from '@segment/analytics-next';
import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';
import { Experiment } from '@/lib/amplitude/create-amplitude-paths';
// @ts-ignore
import experiments from '@/lib/amplitude/paths.json';
import { CookiesEnum } from '@/utils/amplitude/experiment-constants';
import { trackExperienceViewed } from '@/utils/analytics/trackExperienceViewed';
import { useConsentContext } from '@/components/Scripts/OneTrust/ConsentProvider';
interface AnalyticsContextValue {
  analytics: AnalyticsBrowser | any;
}
const AnalyticsContext = createContext<AnalyticsContextValue | undefined>(undefined);
export function useAnalyticsContext() {
  const analyticsContext = useContext(AnalyticsContext);
  if (analyticsContext === undefined) {
    throw new Error('useAnalyticsContext must be used within a AnalyticsProvider');
  }
  return analyticsContext;
}
const setSegmentAnonIdCookie = async (analytics: AnalyticsBrowser) => {
  const date = new Date();
  const time = date.getTime();
  const expTime = time + 3600 * 1000 * 87660;
  date.setTime(expTime);
  const anonymousId: ID = (await analytics.user()).anonymousId();
  if (anonymousId) {
    Cookies.set('segmentAnonId', String(anonymousId), {
      expires: date,
      path: '/'
    });
  }
  return;
};

// Parsing cookie set from middleware, contains experiment data (variant, payload variant, anonymousId )
const getParsedExperimentData = () => {
  const experimentData = Cookies.get(CookiesEnum.AmplitudeExperimentation);
  if (!experimentData) return null;
  try {
    return JSON.parse(experimentData);
  } catch (error) {
    console.error('Error parsing CookiesEnum.AmplitudeExperimentation cookie:', error);
    return null;
  }
};

// Overriding Segment anonymousId value to use middleware UUID based on parsed cookie data
const initializeWithAjsAnonymousId = async (analytics: AnalyticsBrowser) => {
  const parsedData = getParsedExperimentData();
  if (parsedData?.newUserId) {
    ;
    (await analytics.user()).anonymousId(parsedData.newUserId);
    return parsedData.newUserId;
  }
};

// Fetch data to track Experience Viewed event in Segment debugger according to the data required by CRO
const fetchExperimentData = async (analytics: AnalyticsBrowser, pathname: string) => {
  // Get cookie parsed data (pathname) to track events that match
  const parsedData = getParsedExperimentData();
  if (parsedData?.pathname === pathname) {
    // Get dynamic JSON data to populate experiment details properties if flag key matches
    const experiment = (experiments.find((experiment: any) => experiment.key === parsedData.flagKey) as Experiment | undefined);
    if (experiment) {
      const experimentDetails = {
        experience_id: experiment.id,
        experience_name: experiment.name,
        variant_name: parsedData.variant,
        type: 'Experiment or Personalization',
        evaluation: experiment.evaluationMode,
        source: 'marketing-websites',
        state: experiment.state
      };
      const url = window.location.href;
      const path = window.location.pathname;
      const referrer = document.referrer;
      const hostname = window.location.hostname;
      const anonymousId = await initializeWithAjsAnonymousId(analytics);
      trackExperienceViewed(analytics, experimentDetails, url, path, referrer, hostname, anonymousId);
    }
  }
};
export const AnalyticsProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const router = useRouter();
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | any>();
  let {
    strictlyNecessary
  } = useConsentContext();
  useEffect(() => {
    const analyticsBrowser = new AnalyticsBrowser();
    if (!window.analytics) {
      // Make buffered analytics available in the browser for Google Tag Manager
      // Docs: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#load
      if (!window.analytics) window.analytics = analyticsBrowser;
    }

    // Initialize the OneTrust consent wrapper for analytics-next once library is loaded
    // Docs: https://www.npmjs.com/package/@segment/analytics-consent-wrapper-onetrust
    if (!analytics && strictlyNecessary) {
      withOneTrust(analyticsBrowser, {
        disableConsentChangedEvent: true
      }).load({
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY!
        // cdnURL: 'https://cdp.bigcommerce.com' // TODO Enable CDN URL here when CNAME is created via https://bigcommercecloud.atlassian.net/browse/INFRA-16913
      }
      // {
      // 	initialPageview: true,
      // 	integrations: {
      // 	// TODO Enable API Host here when CNAME is created via https://bigcommercecloud.atlassian.net/browse/INFRA-16913
      // 		'Segment.io': {
      // 			apiHost: 'cdp-events.bigcommerce.com/v1',
      // 			// protocol: 'https' // optional
      // 		}
      // 	}
      // }
      );
      analyticsBrowser.ready(async () => {
        // Make analytics instance available in the browser for Google Tag Manager
        window.analytics = analyticsBrowser.instance;

        // Set cookie Segment anonymous Id to populate in Marketo forms
        // TODO: Confirm if this cookie is still needed and remove if not
        if (Cookies.get('segmentAnonId') === undefined) setSegmentAnonIdCookie(analyticsBrowser);
        const ajsAnonymousId = await initializeWithAjsAnonymousId(analyticsBrowser);
        if (ajsAnonymousId) {
          analyticsBrowser.setAnonymousId(ajsAnonymousId);
          Sentry.setUser({
            id: ajsAnonymousId
          });
        }
        setAnalytics(analyticsBrowser);
      });
    }
  }, [analytics, setAnalytics, strictlyNecessary]);
  useEffect(() => {
    const handleRouteChange = () => {
      // Segment Page event call
      analytics.page();
      // Segment Identify: Pass identifiers found in query parameters to Segment Identity Resolution
      const identifers = ['gclid', 'msclkid', 'gbraid', 'msclkid', 'wbraid', '_gl', 'mkt_tok', 'li_fat_id', 'fbclid', '6si_xuid', '_ga'];
      const urlParams = new URLSearchParams(window.location.search);
      const traits: UserTraits = {};
      urlParams.forEach((value, key) => {
        if (identifers.indexOf(key) >= 0) traits[key] = value;
      });
      if (Object.keys(traits).length > 0) analytics.identify(traits);

      //Experiment
      const path = window.location.pathname;
      fetchExperimentData(analytics, path);
    };
    if (!router.isReady || !analytics) return;
    router.events.on('routeChangeComplete', handleRouteChange);
    handleRouteChange();
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [analytics, router]);

  /* Tracks any clicks on the website and sends a marketing event */
  // useEffect(() => {
  // 	const segmentTrackClick = (e: any) => {
  // 		e.stopPropagation()

  // 		const element = e.target as HTMLElement

  // 		return window.analytics.track('Marketing Click', {
  // 			page_url:
  // 				element.baseURI ||
  // 				`${window?.location?.origin}/${
  // 					router.asPath.startsWith('/') ? router.asPath.slice(1) : router.asPath
  // 				}`,

  // 			page_path: router.asPath,
  // 			page_title: document.title,
  // 			page_name: router.asPath,
  // 			page_referrer: document.referrer,
  // 			page_search: window.location.search,
  // 			store_id: null,
  // 			subsection: null,
  // 			label: (element.innerText || element.textContent || element.outerText)
  // 				?.toLowerCase()
  // 				.replace(/\s+/g, '_'),
  // 			text: element.innerText || element.textContent || element.outerText,
  // 			element_class: element.className,
  // 			link_url: 'href' in element && typeof element.href === 'string' ? element.href : null
  // 		})
  // 	}
  // 	document.body.addEventListener('click', segmentTrackClick)

  // 	return () => {
  // 		document.body.removeEventListener('click', segmentTrackClick)
  // 	}
  // }, [])

  return <AnalyticsContext.Provider value={{
    analytics
  }} data-sentry-element="unknown" data-sentry-component="AnalyticsProvider" data-sentry-source-file="AnalyticsProvider.tsx">{children}</AnalyticsContext.Provider>;
};