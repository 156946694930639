export const AMPLITUDE_TRACKING = 'Amplitude Experiment'

export enum TreatmentEnum {
	ON = 'on',
	OFF = 'off',
	CONTROL = 'control',
	CONTROL2 = 'control-2',
	TREATMENT = 'treatment'
}

export enum CookiesEnum {
	AmplitudeExperimentation = 'bc_amplitude_experimentation'
}

export enum StatusEnum {
	RUNNING = 'running'
}

export enum VisibilityEnum {
	Visible = 'visible',
	Invisible = 'invisible'
}
