import { useState } from 'react';
import Script from 'next/script';
import { useConsentContext } from '@/components/Scripts/OneTrust/ConsentProvider';
export function DriftWidget() {
  let {
    functional
  } = useConsentContext();
  const [loadDrift, setLoadDrift] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  if (!loadDrift && !isLoaded && functional) {
    setLoadDrift((): boolean => {
      setIsLoaded(true);
      return !isLoaded && functional;
    });
  } else if (loadDrift && !functional) {
    setLoadDrift((): boolean => {
      setIsLoaded(false);
      return false;
    });
  }
  if (loadDrift) {
    return <Script id="drift-script" dangerouslySetInnerHTML={{
      __html: `
            "use strict";
            !function() {
            var t = window.driftt = window.drift = window.driftt || [];
            if (!t.init) {
                if (t.invoked) return undefined;
                t.invoked = !0, t.methods = ["identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on"], 
                t.factory = function(e) {
                return function() {
                    var n = Array.prototype.slice.call(arguments);
                    return n.unshift(e), t.push(n), t;
                };
                }, t.methods.forEach(function(e) {
                t[e] = t.factory(e);
                }), t.load = function(t) {
                var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                var i = document.getElementsByTagName("script")[0];
                i.parentNode.insertBefore(o, i);
                };
            }
            }();
            drift.SNIPPET_VERSION = '0.3.1';
            drift.load('${process.env.NEXT_PUBLIC_DRIFT_KEY}');
            `
    }} />;
  } else {
    return '';
  }
}